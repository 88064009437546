.baseContactPage {
    text-align: center;
    align-items: center;
    padding: 5%;
    alt : "background image made by Tobias Bjørkli";
    background-image: url("./montains.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
}


.contactTitle {
    color: #28282B;

    font-size: 10vh;

}

.contactFormTitle {
    font-size: 4vh;
}

.form {
    width: 50%;
    margin: auto;
    height: 30vh;
    background: linear-gradient(-45deg, rgb(154, 200, 240), white);
    border-radius: 8px;
    box-shadow: 0 0 40px -10px #000;
    padding: 20px 30px;
    max-width: calc(100vh - 40px);
    box-sizing: border-box;
    font-family: 'Montserrat', sans-serif;
    position: relative;

}

.contactInput {
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    background: none;
    outline: none;
    resize: none;
    border: 0;
    font-family: 'Montserrat', sans-serif;
    transition: all .3s;
    border-bottom: 2px solid #bebed2;
    color: #28282B;
    font-size: 3vh;
}

.contactTextArea {
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    background: none;
    outline: none;
    resize: vertical;
    border: 0;
    font-family: 'Montserrat', sans-serif;
    transition: all .3s;
    border-bottom: 2px solid #bebed2;
    color: #28282B;
    font-size: 2.0vh;
}

.contactTextArea:focus {
    border-bottom: 2px solid #28282B
}

.contactInput:focus {
    border-bottom: 2px solid #28282B
}

.contactP:before {
    content: attr(type);
    display: block;
    margin-top : 3%;
    margin-bottom: 1%;
    font-size: 2.5vh;
    color:#28282B
}

.contactButton {
    padding: 1% 1.5%;
    margin-top : 1.2%;
    font-family: 'Montserrat', sans-serif;
    border: 0.1% solid #78788c;
    background: 0;
    color: #5a5a6e;
    cursor: pointer;
    transition: all .3s;
    font-size: 3vh;
}

button:hover {
    background: #78788c;
    color: #fff
}



.formPhone {
    width: 95%;
    margin-left : auto;
    margin-right: auto;
    height: auto;
    background: linear-gradient(-45deg, rgb(154, 200, 240), white);
    border-radius: 8px;
    box-shadow: 0 0 40px -10px #000;
    padding: 20px 30px;
    max-width: calc(100vh - 40px);
    box-sizing: border-box;
    font-family: 'Montserrat', sans-serif;
    position: relative;

}

.montainImg {
    width: 100%;
    height: 100%;
}

.personnalInfosHandling {
    width: 75%;
    height: auto;
    background: linear-gradient(-45deg, rgb(154, 200, 240), white);
    margin: auto;
    margin-top: 1%;
    padding: 0.5%;
    border-radius: 10px;
}

.personnalInfosP {
    font-size : 1.1vh;
}