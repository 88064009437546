@keyframes zoomIn {
    0% {
      transform: scale(0.95);
    }
    50% {
      transform: scale(1);
    }
    100% {
        transform: scale(0.95);
      }
  }

  @keyframes slideInFromLeft {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(0);
    }
}
 
@keyframes slideInFromRight {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes slideInToLeft {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-100%);
    }
}

@keyframes slideInToRight {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(100%);
  }
}

.myLogoOne {
    align-items: center;
    position: relative;
    left : 0%;
    top : 6%;
    animation: slideInFromLeft 0.2s ease-out;
}

.myLogoTwo {
  position: relative;
  left : 0%;
  top : 30%;
  animation: zoomIn 4s linear infinite;
  animation: slideInFromLeft 0.2s ease-out;
  z-index: -1;
}

.myLogoOne-hidden {
  align-items: center;
  position: relative;
  left : 0%;
  top : 4%;
  animation: slideInToLeft 0.2s ease-out;
}

.myLogoTwo-hidden {
  position: relative;
  left : 0%;
  top : 30%;
  animation: slideInToLeft 0.2s ease-out;
  z-index: -1;
}

.responsiveImgOne {
  animation: zoomIn 4s linear infinite;
  width: 90%;
    height: auto;
}

.responsiveImgTwo {
  animation: zoomIn 4s linear infinite;
  width: 97%;
  height: auto;
}


.subTextOne {
  position: relative;
    text-align: center;
    top : 0vw;
    font-size: 2vw;
}


.subTextTwo {
  position: relative;
  text-align: center;
  top : 0vw;
  font-size: 3vw;
  z-index: -1;
}

.backPage {
  height: 75vw;
  align-items: center;
  text-align: center;
}

.downButtonsPhone {
  position: relative;
  margin: auto;
  top : 25.5vw;
  height: 5%;

}

.downButtons {
  position: relative;
  margin: auto;
  top : 14vh;
  height: 3%;
}

.buttonsPanel {
  margin: 5%;
  margin-top: 0%;
  height: 100%;
  width: auto;
}

@keyframes upAndDown {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-15%);
  }
  100% {
    transform: translateY(0);
  }
}

.buttonsPanel:hover {
  animation : upAndDown 2s linear infinite;
  cursor: pointer;
}

.contactPanel {
  align-items: center;
  position: relative;
  left : 0%;
  top : 6%;
  animation: slideInFromRight 0.2s ease-out;
}

.contactPanelPhone {
position: relative;
left : 0%;
top : 30%;
animation: slideInFromRight 0.2s ease-out;
z-index: 1;
}

.contactPanel-hidden {
  align-items: center;
  position: relative;
  left : 0%;
  top : 6%;
  animation: slideInToRight 0.2s ease-out;
}

.contactPanelPhone-hidden {
position: relative;
left : 0%;
top : 30%;
animation: slideInToRight 0.2s ease-out;
z-index: -1;
}

.upTextOne {
  font-size: 10vh;
  margin: 8%;
  margin-top: 2%;
}

.upTextTwo {
  font-size: 5vh;
  
}

.button {
  width: 50%;
  height: auto;
  font-size : 9vh;
  border-radius: 10px;
  background-image: url("../ContactPage/montains.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  padding: 5vh;
  color : aliceblue;
  margin-bottom: 9vh;
}

.button:hover {
  color : black;
  background-image: url("../ContactPage/montains.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  opacity: 0.8;
}

.buttonOnPhone {
  width: 30%;
  height: auto;
  font-size : 2vh;
  margin: 0.5vh;
  border-radius: 10px;
  background-image: url("../ContactPage/montains.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  padding: 2vh;
  color : aliceblue;
  z-index: 2;
  margin-bottom: 3vh;
}

.buttonOnPhone:hover {
  color : black;
  background-image: url("../ContactPage/montains.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  opacity: 0.8;
}