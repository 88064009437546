.baseLoginPage {
    background-color: #354152;
    display: flex;             
    justify-content: center;   
    align-items: center;       
    width: 100vw;
    height: 100vh;
    position: absolute;
    left: 0;
    top: 0;
}


.modalConnection {
    width: 80%;
    height: max(49vw, 67vh);
    margin: auto;
    background-color: aliceblue;
    align-items: center;
    border-radius: 10px;
    text-align: center;
    box-shadow: -40px 0px 40px -20px rgb(0, 0, 0), 40px 0px 40px -20px rgb(0, 0, 0);
}



.logo {
    width: max(50%, 35vh);
    margin: auto;
    height: auto;
    margin-top: max(5%, 5vh);
}

.loginForm {
    align-items: center;
    width: max(50%, 32vh);
    margin: auto;
}

.loginInput {
    width: 100%;
    height: auto;
    border-radius: 20px;
    font-size: max(2vw, 5vh);
    border: 2px solid #28282B;
    text-align: center;
    background-color: inherit;
    margin: auto;
    margin-top: max(10%, 8vh);
    padding: 1%;
}

.loginInput:focus {
    border: 2px solid white;
}

.loginButton {
    font-size: max(1vw, 2.5vh);
    border-radius: 50px;
    padding: 1%;
    background: linear-gradient(160deg, rgb(223, 239, 252), rgb(148, 205, 224));
    width: 100%;
    height: max(2vw, 3vh);
    border: none;
    margin-top: max(3.5%, 2vh);
    color: #28282B;
}

.loginButton:hover {
    background: rgb(148, 205, 224);
    color: #28282B;
}

.loginButton:focus {
    background: rgb(223, 239, 252);
    border: 1px solid black;
}

.badParameters {
    color: red;
    font-size: max(1.5vw, 1.5vh);
}