.backReal {
    text-align: center;
}

.realizationsTitle {
    color: #28282B;
    font-size : 5vw;
}

.cards {
    margin: auto;
}