.navigation {
    height: 60px;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0.5rem 0rem;
    background-color: 	#28282B;
    color: lightblue;
    font-size: 22px;
    box-shadow: 0 2px 2px 2px rgba(9, 9, 9, 0.23);
    text-align: center;
}

.brand-name {
    text-decoration: none;
    color: lightblue;
    font-size: 25px;
    margin-left: 1rem;
}
.navigation-menu {
margin : auto;
}

.navigation-menu ul {
    display: flex;
    color: lightblue;
    padding: 0;
}

.navigation-menu li {
    list-style-type: none;
    color: lightblue;
}

.navigation-menu li a {
    text-decoration: none;
    display: block;
    color: lightblue;
    width: 100%;
}

.navigation-menu li a:hover {
    color: aliceblue;
    cursor: pointer;
}

.navigation-menu li a:focus {
    color : white;
    cursor: pointer;
}
.menuButton {
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: lightblue;
    font-size: 3rem;
}

@keyframes slideInFromLeft {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(0);
    }
}
  
@keyframes slideInToLeft {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-100%);
    }
}

.leftPanel {
    position: relative;
    width: 180px;
    left : 0;
    display: grid;
    background-color: #28282B;
    animation: slideInFromLeft 0.5s ease-out;
    height: 100%;
    z-index: 2;
}

.leftPanel-hidden {
    position: relative;
    width: 180px;
    left : 0;
    display: grid;
    background-color: #28282B;
    animation: slideInToLeft 0.3s ease-out;
    height: 100%;
    z-index: 2;
}

.firstStackedButton {
    height: 50px;
    border: none;
    margin-top : 10px;
    width: 180px;
    font-size: 20px;
    color : lightblue;
    background-color: #28282B;
    margin-bottom: 10px;
}

.firstStackedButton:hover {
    background-color: #2e2e31;
}

.firstStackedButton:focus {
    background-color: aliceblue;
    color : #28282B;
}

.stackedButton:hover {
    background-color: #2e2e31;
}

.stackedButton:focus {
    background-color: aliceblue;
    color : #28282B;
}

.stackedButton {
    height: 50px;
    border: none;
    margin-top : 10px;
    width: 180px;
    font-size: 20px;
    color : lightblue;
    background-color: #28282B;
    margin-bottom: 10px;
    margin-top: 30px;
    
}

.onTop {
    width: 100%;
    height: auto;
    margin-bottom: 60px;
}