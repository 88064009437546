@keyframes scalePop {
    0% {
      transform: scale(0.5);
    }
    100% {
      transform: scale(1);
    }
  }

.backOfPage {
    position: relative;
    background-color : #28282bcb;
    opacity: 1;
    text-align: center;
    animation: none;
    animation: scalePop 0.5s ease-out;
    z-index: 0;
    align-items: center;
    justify-content: center;
}

.competencesTitle {
    color: lightblue;
    font-size : 5vw;
    margin: auto;
}

.competencesCards {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    grid-gap: 10px;
    width: 90%;
    margin-top: 6%;
    margin-left: auto;
}


.competencesCardsPhone {
  width: 50%;
  margin-top: 10%;
  margin-left: auto;
  margin-right: auto;
}
