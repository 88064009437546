.mainPanel {
    text-align: center;
    align-items: center;
}

.allContent {
    margin-top: 90px;
}

.welcomeText {
    font-size: max(3vw, 3vh);
}