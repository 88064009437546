.card {
    background-color: #3e3e42;
    border: 3px solid black;
    border-radius: 10px;  
    width: 100%;
    align-items: center;
    margin-top: 0px;
}

.container
{
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 25%;
}

.container .box
{
  position: relative;
  width: 65%;
  height: 68%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.5s;
}

.container .box::before
{
  content:' ';
  position: absolute;
  top: 0;
  left: 50px;
  width: 50%;
  height: 100%;
  text-decoration: none;
  background: #fff;
  border-radius: 8px;
  transform: skewX(15deg);
  transition: 0.5s;
}

.container .box::after
{
  content:'';
  position: absolute;
  top: 0;
  left: 50;
  width: 50%;
  height: 100%;
  background: #fff;
  border-radius: 8px;
  transform: skewX(15deg);
  transition: 0.5s;
  filter: blur(30px);
}

.container .box:hover:before,
.container .box:hover:after
{
  transform: skewX(0deg);
  left: 0px;
  width: 100%;
  
}

.container .box:nth-child(1):before,
.container .box:nth-child(1):after
{
  background: linear-gradient(315deg, #28282B,  #7393B3)
}

.container .box .content
{
  position: relative;
  left: 0;
  padding: 20px 40px;
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(10px);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  z-index: 1;
  transform: 0.5s;
  color: #fff;
}

.sizeImg {
    height: 5vw;
    width: auto;
    margin: 10%;
}

.sizeImgPhone {
  height: 10vw;
  width: auto;
  margin: 10%;
}

.titleCard {
    color: lightblue;
    font-size: 1.5vw;
}

.titleCardPhone {
  color: lightblue;
  font-size: 3vw;
}

.containerPhone
{
  margin-bottom: 10%;
  width: 100%;
  height: 90%;
}

.containerPhone .box
{
  position: relative;
  width: 100%;
  height: 100%;
  transition: 0.5s;
  z-index: 1;
}

.containerPhone .box::before
{
  content:' ';
  position: absolute;
  top: 0;
  left: 50px;
  width: 50%;
  height: 100%;
  text-decoration: none;
  background: #fff;
  border-radius: 8px;
  transform: skewX(15deg);
  transition: 0.5s;
}

.containerPhone .box::after
{
  content:'';
  position: absolute;
  top: 0;
  left: 50;
  width: 50%;
  height: 100%;
  background: #fff;
  border-radius: 8px;
  transform: skewX(15deg);
  transition: 0.5s;
  filter: blur(30px);
}

.containerPhone .box:hover:before,
.containerPhone .box:hover:after
{
  transform: skewX(0deg);
  left: 0px;
  width: 100%;
  
}

.containerPhone .box:nth-child(1):before,
.containerPhone .box:nth-child(1):after
{
  background: linear-gradient(315deg, #28282B,  #7393B3)
}

.containerPhone .box .content
{
  position: relative;
  left: 0;
  padding: 20px 40px;
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(10px);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  z-index: 1;
  transform: 0.5s;
  color: #fff;
}