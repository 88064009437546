body {
  margin: auto;
  overflow: auto;
  background: linear-gradient(315deg, aliceblue 0%, rgb(130, 186, 235) 50%, rgba(60,132,206,1) 100%);
  animation: gradient 5s ease infinite;
  background-size: 400% 400%;
  background-attachment: fixed;
  font-family: 'Montserrat', Regular;
  color : #28282B;
}

::-webkit-scrollbar {
  width: auto;
}

::-webkit-scrollbar-track {
  background: lightblue;
}

::-webkit-scrollbar-thumb {
  background: #28282B;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.font-link {
  font-family: 'Montserrat', Regular;
}

@keyframes gradient {
  0% {
      background-position: 0% 0%;
  }
  50% {
      background-position: 100% 100%;
  }
  100% {
      background-position: 0% 0%;
  }
}

.linkToLegal {
  align-items: center;
  text-align: center;
}

.divLinkLegalMentions {
  background-color: aliceblue;
  width: 20%;
  margin: auto;
  margin-top: 1vh;
  margin-bottom: 1vh;
  border-radius: 10px;
}

.linkLegalMentions {
  color : #28282B;
  font-size: 100%;
}

.linkLegalMentions:hover {
  color : lightblue;
}