.baseLegalMentions {
    align-items: center;
    text-align: center;
    background-color: aliceblue;
    width: 95%;
    margin: auto;
    height: auto;
    margin-bottom: 1vh;
    margin-top: 1vh;
}

.legalMentionsh1 {
    margin-top: 1vh;
    font-size : 5.5vh;
}

.legalMentionsh2 {
    margin-top: 4vh;
    font-size : 4vh;
}

.legalMentionsP {
    font-size : 2.5vh;
    margin-top: 2vh;
}

.sectionMentions {
    margin-top: 10vh;
}