.allTextEdit {
    height: 80vh;
    background-color: #28282b;
    width: 100%;
    align-items: center;
    margin-top: 5%;
}

.textEditWelcome {
    color : aliceblue;
    font-size: max(1.5vw, 2vh);
    margin-bottom: 2%;
}
.textScrollableList {
    max-height:  65vh;
    width: auto;
    background-color: #404044;
    width: 70%;
    align-items: center;
    margin: auto;
    overflow-y: scroll;
}

.buttonList {
    height: auto;
    width: 30%;
    border: 1px solid black;
    border-radius: 5px;
    margin-top: 2%;
    background-color: aliceblue;
    font-size: max(1vw, 2vh);
    text-align: center;
}

.buttonList:hover {
    background-color: lightblue;
    color: #28282b;
}

.buttonList:focus {
    background-color: #28282b;
    color: aliceblue;
}

.inputList {
    height: auto;
    width: 95%;
    border: 1px solid black;
    border-radius: 5px;
    margin-top: 2%;
    background-color: aliceblue;
    font-size: max(1vw, 2vh);
    text-align: center;
}

.inputList:hover {
    background-color: lightblue;
    color: #28282b;
}

.inputList:focus {
    color: #28282b;
    background-color: aliceblue;
}