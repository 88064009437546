.baseImage {
    height: auto;
    width: 100%;
    align-items: center;
    margin-top: 5%;
}

.imageEditWelcome {
    color : #28282B;
    font-size: max(1.5vw, 2vh);
    margin-bottom: 1%;
}

.imageLittleText {
    color : #28282B;
    font-size: max(1vw, 1.5vh);
    margin-bottom: 1%;
}

@media (min-width: 300px) {
    .gridImages { grid-template-columns: repeat(1, 1fr); }
}

@media (min-width: 900px) {
    .gridImages { grid-template-columns: repeat(2, 1fr); }
}

.gridImages {
    display: grid;
    gap: 1vw;
    width: 97%;
    margin: auto;
}

.oneImage {
    width: 100%;
    height: auto;
    cursor: pointer;
}

@keyframes zoomInPanel {
    0% {
        transform: translate(-50%, -50%) scale(0.1);
    }
    100% {
    transform: translate(-50%, -50%) scale(1);
    }
}

.globalPanel {
    width: 60%;
    height: auto;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    border: 1px solid #ccc;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.3);
    z-index: 9999;
    animation: zoomInPanel 0.5s ease-out;
}

.endButtons {
    margin-top: 1%;
    display: flex;
    justify-content: space-between;
}

.endButtonValid {
    width: 30%;
    height: auto;
    font-size: max(1vw, 1.5vh);
    background-color: lightgreen;
    border-radius: 10px;
}

.endButtonAnnul {
    width: 30%;
    height: auto;
    font-size: max(1vw, 1.5vh);
    background-color: lightcoral;
    border-radius: 10px;
}

.endButtonValid:hover {
    background-color: rgb(203, 241, 203);
    color: #28282B;
}

.endButtonAnnul:hover {
    background-color: rgb(243, 195, 195);
    color: #28282B;
}

.inputImage {
    margin-bottom : 5%;
}