    
  *,
  *::after,
  *::before {
    margin: 0;
    padding: 0;
    -webkit-box-sizing: inherit;
            box-sizing: inherit;
  }
  
  
  
  
  .card {
    -webkit-perspective: 150rem;
            perspective: 150rem;
    -moz-perspective: 150rem;
    position: relative;
    height: 42vw;
    width: 70%;
    margin: 5%;
    display: block;
    margin-right: auto;
    margin-left: auto;
  }
  
  .card__side {
    height: 42vw;
    -webkit-transition: all 0.8s ease;
    transition: all 0.8s ease;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    border-radius: 3px;
    overflow: hidden;
    -webkit-box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, 0.15);
            box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, 0.15);
  }
  
  .card__side--front {
    background-color: #fff;
  }
  
  .card__side--front-1 {
    background: linear-gradient(-45deg, aliceblue, #64b5f6);
  }
    
  .card__side--back {
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
  }
  
  .card__side--back-1 {
    background: linear-gradient(-45deg, #64b5f6, aliceblue);
  }
  
  
  .card:hover .card__side--front-1 {
    -webkit-transform: rotateY(-180deg);
            transform: rotateY(-180deg);
  }
  
  .card:hover .card__side--back {
    -webkit-transform: rotateY(0);
            transform: rotateY(0);
  }
  
  .card__title {
    height: 20rem;
    padding: 4rem 2rem 2rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  
  .card__title--1 .fas {
    font-size: 5rem;
  }
  
  .card__heading {
    font-size: 4vw;
    font-weight: 300;
    text-transform: uppercase;
    text-align: center;
    color: #fff;
  }
  
  .card__heading-span {
    padding: 1rem 1.5rem;
    -webkit-box-decoration-break: clone;
    box-decoration-break: clone;
  }
  
  .card__details {
    padding: 0 2rem 2rem;
  }
  
  .card__details ul {
    list-style: none;
    width: 80%;
    margin: 0 auto;
  }
  
  .card__details ul li {
    text-align: center;
    font-size: 1.5rem;
    padding: 1rem;
  }
  
  .card__details ul li:not(:last-child) {
    border-bottom: 1px solid #eee;
  }
  
  .card__cta {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    width: 90%;
    text-align: center;
  }
  
  .card__price-box {
    text-align: center;
    color: #fff;
    margin-bottom: 8rem;
  }
  
  .cardBackDescription {
    margin: 7%;
    font-size: 2.5vw;
    color : #6c6c74;
  }
  
  .cardBackTitle {
    margin-top: 7%;
    font-size: 3.2vw;
    font-weight: 100;
    color : #28282B;
  }
    
  
  .cardSizeImg {
    height: auto;
    width: 95%;
  }