.upImg {
  margin-top: 0px;
    height: 8vh;
    width: auto;
    cursor: pointer;
  }

@keyframes upAndDown {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-15%);
  }
  100% {
    transform: translateY(0);
  }
}

.upButtonCenter {
    position: fixed;
    left : 90%;
    top : 80%;
    z-index: 2;
    animation: upAndDown 2s linear infinite;
}

.upButtonCenterPhone {
  position: fixed;
  left : 82%;
  top : 80%;
  z-index: 2;
  animation: upAndDown 2s linear infinite;
}

.upButtonCenterPhone:hover,
.upButtonCenter:hover {
  animation: upAndDown 0.5s linear infinite;
}

